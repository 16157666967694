module.exports = {
    header: {
        text1: '心靈之境',
        text2: '手中佛龕',
        text3: '一花一世界 一葉一菩提',
        text4: '滿足不同供奉需求',
        text5: '精美個性化的供佛',
        text6: '佛海繁花 虔誠如一',
        text7: '眾多流派的佛像供您選擇',
        text8: '滿足虔誠信仰需求',
        text9: '開啟你的專屬靈境佛龕',
        text10: '免費下載 即刻開始虔誠禮佛之旅',
    }
}