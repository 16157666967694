module.exports = {
    header: {
        textOne: 'མུ་ཏིག་ཁུག་མ།',
        textTwo: 'མུ་ཏིག',
        text3: 'སྤོས་ཤེལ།',
        text4: 'ཉོས་ཚར།',
        text5: 'དངུལ་སྤྲད་མ་ཐུབ།',
        text6: 'བརྗེ་ཚར།',
        text7: 'མུ་ཏིག་མདང་མ་སོང་།',
        text8: 'རི་རྒྱལ་ཚོགས་མི།',
        text9: ' CuckooZenནང་གི་སྐྱེས་མཆོག',
        text10: 'ཉིན་རེའི་འབྱོར་ཐོ་བཀོད་དུས། ཕྱག་རྟགས་གྱ་ནོམ་པ་རེ།',
        text11: 'ཚོགས་མིས་མཆོད་རྫས་ཉོ་དུས་ཚང་མ་གོང་ཕྱེད་ཡིན།',
        text12: 'ཞུགས།',
        text13: 'གྲོས་ཆིངས།',
        text14: 'བསྐྱར་བསོ།',
        text15: 'གསང་ཆིངས།',
    }
}