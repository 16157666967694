import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './lang'
//判断当前环境是IOS还是安卓
let u = navigator.userAgent;
// let appVersion = navigator.appVersion;
let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
import animate from 'animate.css'; 


const app = createApp(App)

app.config.globalProperties.$isAndroid = isAndroid;
app.config.globalProperties.$isIOS = isIOS;

app.use(i18n)
app.use(animate)
app.use(router).mount('#app')


