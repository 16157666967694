import { createI18n } from 'vue-i18n'

import zh_CN from './zh-CN'
import zh_HK from './zh-HK'
import bo_CN from './bo-CN'
import en_US from './en-US'

// 语言库
const messages = {
  'zh-CN': zh_CN,
  'zh-HK': zh_HK,
  'bo-CN': bo_CN,
  'en-US': en_US
}

// 默认语言
// const langDefault = 'zh-CN'
const langDefault = 'zh-HK'

const i18n = createI18n({
  locale: langDefault,		//默认显示的语言 
  messages
})

export default i18n; // 将i18n暴露出去，在main.js中引入挂载