module.exports = {
    header: {
        text1: 'A pure Land of Mind',
        text2: 'a Buddhist niche in hand',
        text3: 'A world in a wild flower and a bodhi in a leaf',
        text4: 'Different ways of praying meeting many diversity needs.',
        text5: 'Numerous Buddhas bless diversity belivers',
        text6: 'There are to many Buddhas choose one to make your own wish',
        text7: 'A sincere heart can lead to good luck',
        text8: "Pray for Buddha's blessing",
        text9: 'Begin your exclusiv Buddhist niche',
        text10: 'Download for free start your sincere Buddhist journey',
    }
}