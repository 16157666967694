import {
    createRouter,
    createWebHashHistory
} from 'vue-router'
import homePage_cn from '../views/home-page/index.vue'
import homePage_en from '../views/home-page-en/index.vue'

//路由数组
const routes = [{
        path: "/",
        component: homePage_en,
    },
    {
        path: "/home-page",
        component: homePage_en,
    },
    {
        path: "/home-page-cn",
        component: homePage_cn,
    },

]

//路由对象
const router = createRouter({
    history: createWebHashHistory(),
    routes //上面的路由数组

})

//导出路由对象，在main.js中引用
export default router