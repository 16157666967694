module.exports = {
    header: {
        textOne: '珍珠袋',
        textTwo: '珍珠',
        text3: '琥珀',
        text4: '购买成功',
        text5: '支付失败',
        text6: '兑换成功！',
        text7: '珍珠不足',
        text8: '须弥会员',
        text9: 'CuckooZen 的尊贵身份',
        text10: '每日签到，获得丰厚礼品',
        text11: '购买贡品，享受半价优惠',
        text12: '订阅',
        text13: '协议',
        text14: '修复购买',
        text15: '隐私',
    }
}