<template>
    <div class="home-container">
        <div class="first-part">
            <!-- <img src="../../assets/img/home-bg-1.png" alt="#"> -->
            <div class="logo-left">
                <img src="../../assets/img/logo.png" alt="#">
            </div>
            <div class="first-title">
                <img src="../../assets/text-img/text1.png" alt="#">
            </div>
            <div class="first-img-box">
                <div class="cloud-1">
                    <img src="../../assets/img/clouds-1.png" alt="#">
                </div>
                <div class="cloud-2">
                    <img src="../../assets/img/clouds-2.png" alt="#">
                </div>
                <div class="first-round-img">
                    <img src="../../assets/img/first-round.png" alt="#">
                </div>
                <div class="buddha-img">
                    <img src="../../assets/img/Buddha.png" alt="#">
                </div>
            </div>
            <div class="content-box">
                <div class="qr-left" @mouseenter="handleMouseOver" @mouseleave="handleMouseOut">
                    <div class="top-box">
                        <div class="ios-icon">
                            <img v-if="!ishover" src="../../assets/img/ios-white.png" alt="#">
                            <img v-if="ishover" src="../../assets/img/ios-dark.png" alt="#">
                        </div>
                        <p>iOS</p>
                    </div>
                    <div v-if="ishover" class="qer-code">
                        <img src="../../assets/img/qr-code-ios.png" alt="#">
                    </div>
                </div>
                <div class="qr-right" @mouseenter="handleRightMouseOver" @mouseleave="handleRightMouseOut">
                    <div class="top-box">
                        <div class="android-icon">
                            <img v-if="!isRightHover" src="../../assets/img/logo-android-white.png" alt="#">
                            <img v-if="isRightHover" src="../../assets/img/logo-android-dark.png" alt="#">
                        </div>
                        <p>Android</p>
                    </div>
                    <div v-if="isRightHover" class="qer-code">
                        <img src="../../assets/img/qr-code-Android.png" alt="#">
                    </div>
                </div>
            </div>

            <div class="lang-change-box" @click="goEn()">
                <img src="../../assets/img/lan-change.png" alt="#">
            </div>
        </div>
        <div class="second-part">
            <div class="width-1080">
                <div class="part-left" ref="trigger" :class="{ 'play-animation': playAnimation }">
                    <img src="../../assets/text-img/text2.png" alt="#">
                </div>
                <div class="part-right" :class="{ 'play-animation': playAnimation }">
                    <div class="part-right-img-1">
                        <img src="../../assets/img/part-2-img-1.png" alt="#">
                    </div>
                    <div class="part-right-img-2" :class="{ 'play-animation': playAnimation }">
                        <img src="../../assets/img/part-2-img-2.png" alt="#">
                    </div>
                </div>
            </div>

        </div>
        <div class="third-part">
            <div class="width-1080">
                <div class="part-text" ref="trigger_1" :class="{ 'play-animation': playAnimation_1 }">
                    <img src="../../assets/text-img/text3.png" alt="#">
                </div>
                <div class="third-bg-img" :class="{ 'play-animation': playAnimation_third_right }">
                    <img src="../../assets/img/part-3-img-2.png" alt="#">
                </div>
                <div class="third-content-img" ref="trigger_third" :class="{ 'play-animation': playAnimation_third_right }">
                    <img src="../../assets/img/part-3-img-1.png" alt="#">
                </div>
            </div>
        </div>
        <div class="fourth-part">
            <div class="width-1080">
                <div class="part-text" ref="trigger_2" :class="{ 'play-animation': playAnimation_2 }">
                    <img src="../../assets/text-img/text4.png" alt="#">
                </div>
            </div>

            <div class="bottom-img-box">
                <div class="part-img-content">
                    <img src="../../assets/img/part-4-img-1.gif" alt="#">
                </div>
                <div class="part-img-bottom">
                    <img src="../../assets/img/botom-position.png" alt="#">
                </div>
            </div>


            <div class="light-img-box light-img-box-1">
                <img src="../../assets/img/part-4-light.png" alt="#">
            </div>
            <div class="light-img-box light-img-box-2">
                <img src="../../assets/img/part-4-light.png" alt="#">
            </div>
            <div class="light-img-box light-img-box-3">
                <img src="../../assets/img/part-4-light.png" alt="#">
            </div>
            <div class="light-img-box light-img-box-4">
                <img src="../../assets/img/part-4-light.png" alt="#">
            </div>
        </div>
        <div class="fifth-part">
            <div class="part-img-box">
                <img src="../../assets/img/part-5-img-1.png" alt="#">
                <div class="part-text" ref="trigger_3" :class="{ 'play-animation': playAnimation_3 }">
                    <!-- <p class="left-text-1">開啟你的專屬靈境佛龕</p>
                    <p class="left-text-2">免費下載 即刻開始虔誠禮佛之旅</p> -->
                    <img src="../../assets/text-img/text5.png" alt="#">
                </div>

            </div>
        </div>
        <div class="bottom-part">
            <div class="icon-box">
                <div class="qr-left">
                    <div class="ios-icon">
                        <img src="../../assets/img/ios-white.png" alt="#">
                    </div>
                    <p>iOS</p>
                </div>
                <div class="qr-right">
                    <div class="android-icon">
                        <img src="../../assets/img/logo-android-white.png" alt="#">
                    </div>
                    <p>Android</p>
                </div>
            </div>
            <p class="bottom-text">@ CuckooZen | 聯繫 | <br />Address：cuckoo.buddhism@gmail.com</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'homePage',
    data() {
        return {
            ishover: false,
            isRightHover: false,
            playAnimation: false,
            playAnimation_1: false,
            playAnimation_2: false,
            playAnimation_3: false,
            playAnimation_third_right: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            let triggerElement = this.$refs.trigger;
            let triggerPosition = triggerElement.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let trigger_1 = this.$refs.trigger_1;
            let triggerPosition_1 = trigger_1.getBoundingClientRect().top;
            let trigger_2 = this.$refs.trigger_2;
            let triggerPosition_2 = trigger_2.getBoundingClientRect().top;
            let trigger_3 = this.$refs.trigger_3;
            let triggerPosition_3 = trigger_3.getBoundingClientRect().top;
            let trigger_third_right = this.$refs.trigger_third;
            let triggerPosition_3_right = trigger_third_right.getBoundingClientRect().top;

            if (triggerPosition_3_right - windowHeight <= 0) {
                this.playAnimation_third_right = true;
            }

            if (triggerPosition_3 - windowHeight <= 0) {
                this.playAnimation_3 = true;
            }

            if (triggerPosition_2 - windowHeight <= 0) {
                this.playAnimation_2 = true;
            }

            if (triggerPosition_1 - windowHeight <= 0) {
                this.playAnimation_1 = true;
            }

            if (triggerPosition - windowHeight <= 0) {
                this.playAnimation = true;
                // window.removeEventListener('scroll', this.handleScroll); // 可选，如果只想触发一次动画
            }
        },

        goEn() {
            this.$router.push({
                path: '/home-page'
            })
        },

        // 根据传参进行语言环境切换
        changelang(lang) {
            console.log('成功进行预演切换', lang);
            this.$i18n.locale = lang
        },

        handleMouseOver() {
            this.ishover = true;
        },
        handleMouseOut() {
            this.ishover = false;
        },

        handleRightMouseOver() {
            console.log('进入区域')
            this.isRightHover = true;
        },
        handleRightMouseOut() {
            console.log('离开区域')
            this.isRightHover = false;
        }
    }
}
</script>

<style lang="less" scoped>
.home-container {
    width: 100%;
    height: 100%;
    background: #F3EBDC;
    position: relative;

    .logo-left {
        position: absolute;
        left: 90px;
        top: 60px;

        width: 291px;
        height: 60px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .width-1080 {
        width: 1080px;
        margin: auto;
    }

    .first-part {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #2F1F1B 0%, #7A473B 100%);
        position: relative;
        overflow: hidden;

        .first-title {
            width: 20.833vw;
            position: absolute;
            top: 253px;
            left: 19.89vw;
            z-index: 999;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .first-img-box {
            width: 100%;
            height: 100%;
            position: relative;

            .cloud-1 {
                position: absolute;
                left: -280px;
                bottom: 71px;
                width: 650px;
                height: 261px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .cloud-2 {
                position: absolute;
                left: 48.822vw;
                top: 138px;
                width: 392px;
                height: 240px;
                z-index: 99;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .first-round-img {
                width: 48.958vw;
                height: 48.958vw;
                position: absolute;
                right: -5.208vw;
                top: -27.777777vh;
                animation: rotate 60s infinite linear;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .buddha-img {
                width: 59.479vw;
                // height: 82.407vh;
                position: absolute;
                top: -50px;
                right: -11.979vw;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .lang-change-box {
            width: 60px;
            height: 60px;
            position: absolute;
            right: 90px;
            top: 60px;

            img {
                width: 100%;
                height: 100%;
            }
        }


        .content-box {
            position: absolute;
            left: 19.65vw;
            bottom: 43px;
            display: flex;

            .top-box {
                display: flex;
                justify-content: center;
                align-self: center;
                margin-top: 5px;
            }

            .qr-left {
                width: 180px;
                height: 220px;
                border-radius: 20px;

                .ios-icon {
                    width: 32px;
                    height: 32px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .qr-right {
                margin-left: 137px;
                width: 180px;
                height: 220px;
                border-radius: 20px;

                .android-icon {
                    width: 32px;
                    height: 32px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .qr-left:hover,
            .qr-right:hover {
                background: #FFFFFF;

                p {
                    color: #724338;
                }
            }

            .qer-code {
                width: 150px;
                height: 150px;
                border-radius: 10px;
                margin: 0 auto;
                margin-top: 10px;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                margin-left: 10px;
                font-size: 32px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 36px;
            }
        }
    }

    .second-part {
        width: 100%;
        height: 890px;
        background: url('../../assets/img/home-bg-2.png') no-repeat;
        background-size: 100% 100%;


        .width-1080 {
            width: 1080px;
            margin: auto;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-top: 213px;
        }

        .part-left {
            width: 369px;

            img {
                width: 100%;
            }
        }

        .part-left.play-animation {
            animation: iconHover 3s linear;
        }

        .part-right {
            margin-left: 128px;
            display: flex;

            .part-right-img-1 {
                width: 400px;
                height: 564px;
                z-index: 9;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .part-right-img-2 {
                width: 68px;
                height: 466px;
                margin-left: -120px;
                margin-top: 2px;


                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            .part-right-img-2.play-animation {
                animation: moveRight 3s linear;
                animation-fill-mode: forwards;
                animation-delay: 3s;
            }
        }

        .part-right.play-animation {
            animation: iconHover 3s linear;
        }

    }

    .third-part {
        width: 100%;
        height: 890px;
        background: url('../../assets/img/home-bg-3.png') no-repeat;
        background-size: 100% 100%;
        position: relative;


        .width-1080 {
            width: 1080px;
            height: 890px;
            margin: auto;
            box-sizing: border-box;
            padding-top: 173px;
            position: relative;
        }

        .part-text {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 297px;

            img {
                width: 100%;
            }
        }

        .part-text.play-animation {
            animation: iconHover 3s linear;
        }

        .third-bg-img {
            width: 551px;
            height: 552px;
            margin: 0 auto;


            img {
                width: 100%;
                height: 100%;
                display: block;
                animation: rotate 20s infinite linear;
                animation-delay: 3s;
            }
        }

        .third-bg-img.play-animation {
            animation: iconHover 3s linear;
        }

        .third-content-img {
            width: 1165px;
            height: 520px;
            position: absolute;
            left: 50%;
            bottom: 60px;
            margin-left: -582.5px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .third-content-img.play-animation {
            animation: imgMove 3s linear;
        }
    }

    .fourth-part {
        width: 100%;
        height: 890px;
        background: url('../../assets/img/home-bg-4.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        box-sizing: border-box;

        .width-1080 {
            width: 1080px;
            margin: 0 auto;
            position: relative;

            .part-text {
                position: absolute;
                left: 0;
                top: 98px;
                width: 297px;

                img {
                    width: 100%;
                }
            }
        }

        .part-text.play-animation {
            animation: iconHover 3s linear;
        }

        .bottom-img-box {
            .part-img-content {
                width: 460px;
                height: 470px;
                position: absolute;
                left: 50%;
                margin-left: -350px;
                bottom: 153px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .part-img-bottom {
                width: 813px;
                height: 175px;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -456.5px;


                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }



        .light-img-box {
            width: 174px;
            height: 174px;
            display: flex;
            justify-content: space-around;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .light-img-box-1 {
            position: absolute;
            left: 7.23vw;
            bottom: 30px;
        }

        .light-img-box-2 {
            position: absolute;
            left: 24.375vw;
            bottom: 107px;
        }

        .light-img-box-3 {
            position: absolute;
            right: 22.135vw;
            bottom: 89px;
        }

        .light-img-box-4 {
            position: absolute;
            right: 7.1875vw;
            bottom: 14px;
        }

        .light-img-box {
            width: 174px;
            height: 174px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .fifth-part {
        width: 100%;
        height: 632px;
        background: #F3EBDC;
        box-sizing: border-box;
        padding-top: 124px;

        .part-img-box {
            width: 875px;
            height: 155px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .part-text {
            width: 600px;
            margin: 0 auto;

            img {
                width: 100%;
            }
        }

        .part-text.play-animation {
            animation: iconHover 3s linear;
        }

        // .left-text-1 {
        //     margin-top: 27px;
        //     font-size: 60px;
        //     font-family: '楷体';
        //     font-weight: 900;
        //     color: #5F4D38;
        //     text-align: center;
        // }

        // .left-text-2 {
        //     margin-top: 20px;
        //     font-size: 28px;
        //     font-family: '楷体';
        //     font-weight: 400;
        //     color: #5F4D38;
        //     text-align: center;
        // }
    }

    .bottom-part {
        width: 100%;
        height: 218px;
        background: #252525;
        box-sizing: border-box;
        padding-top: 60px;

        .bottom-text {
            margin-top: 60px;
            text-align: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.3);
            line-height: 20px;
        }

        .icon-box {
            display: flex;
            justify-content: center;

            .qr-left {
                margin-right: 90px;
            }

            .qr-right {
                margin-left: 90px;
            }

            >div {
                display: flex;

                >p {
                    margin-left: 10px;
                    font-size: 32px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 32px;
                }

                .ios-icon {
                    width: 32px;
                    height: 32px;

                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .android-icon {
                    width: 32px;
                    height: 32px;

                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}


@keyframes iconHover {
    0% {
        margin-top: 200px;
        opacity: 0;
    }

    0% {
        margin-top: 150px;
        opacity: 0.25;
    }

    50% {
        margin-top: 100px;
        opacity: 0.5;
    }

    50% {
        margin-top: 50px;
        opacity: 0.75;
    }

    100% {
        margin-top: 0px;
        opacity: 1;
    }
}

@keyframes moveRight {
    0% {
        margin-left: -90px;
        opacity: 0;
    }


    50% {
        margin-left: -30px;
        opacity: 0.5;
    }


    100% {
        margin-left: 32px;
        opacity: 1;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes imgMove {
    0% {
        bottom: -140px;
        opacity: 0;
    }

    0% {
        bottom: -90px;
        opacity: 0.25;
    }


    50% {
        bottom: -40px;
        opacity: 0.5;
    }

    50% {
        bottom: 10px;
        opacity: 0.75;
    }


    100% {
        bottom: 60px;
        opacity: 1;
    }
}
</style>